











import { Component } from "vue-property-decorator"

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaActivitiesPageIndex from '@/feature/Shared/Activity/PgaActivitiesPageIndex.vue';

@Component({
	components:
	{
		PgaActivitiesPageIndex,
	}
})
export default class MyActivitiesIndex extends mixins(SharedMixin) { }
